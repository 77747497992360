import React from "react";
import Home from "./pages/Home/Home";

function App() {
    return (
        <div className="home">
            <Home />
        </div>
    )
}

export default App;